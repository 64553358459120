#buttonCommit {
    background-color: #e9434e!important;
    color: white!important;
    border-color: #e9434e!important;
    border-radius: 15px!important;
  }
#buttonCommit:hover ~ #teste  {
    background-color: #9e2830;
    color: white!important;
  }
  
#buttonSubmitRoteiros{
    color: white!important;
  }

  
