.TextosAdm{
   font-family: NunitoBold!important;
}

.TextosAdmSize{
   font-size: calc(0.5em + 0.5vw)!important;
}

.dropdownAdm{
    background-color: #3e3d90!important;
    color: white!important;
}

.dropdownAdm2{
   background-color: #e7424d!important;
   color: white!important;
   border: none;
}
.dropdownAdm2:hover{
   background-color: #772227!important;
   color: white!important;
   border: none;
}

.TextosAuxiliares3{
    text-decoration: none;
    color: #3e3d90!important;
    font-family: NunitoBold!important;
    font-size: calc(1em + 0.4vw)!important;
 }

 .p-listbox-item{
    text-decoration: none;
    color: white!important;
    font-family: NunitoBold!important;
    font-size: calc(0.5em + 0.4vw)!important;
 }

 .p-listbox-list{
    border: none;
    background-color: #3e3d90!important;
 }
 .p-highlight{
    color: #e7424d!important;
    background-color: white!important;
 }
 