/* CarouselDemo.css */

.carousel-demo .product-item .product-item-content {
    margin: .3rem;
    min-height: 10rem;
    max-height: 10rem;
}

.carousel-demo .product-item .product-image {
    width: 100%;
    max-height: 9rem;
}