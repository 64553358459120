@font-face {
    font-family: NunitoExtraBold;
    src: url("/fontes/Nunito-ExtraBold.ttf");
  }
  @font-face {
    font-family: NunitoBold;
    src: url("/fontes/Nunito-Bold.ttf");
  }
  @font-face {
    font-family: NunitoRegular;
    src: url("/fontes/Nunito-Regular.ttf");
  }
  @font-face {
    font-family: NunitoSemiBold;
    src: url("/fontes/Nunito-SemiBold.ttf");
  }
  
  body{
    font-family: NunitoSemiBold;
  }

  .negrito{
    font-family: NunitoBold;
  }